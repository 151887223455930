export default class Assignment {
    name;
    description;

    constructor(name, description) {
        this.name = name;
        this.description = description;
    }

    static fromResponse(response) {
        return new Assignment(response.name, response.description)
    }
}