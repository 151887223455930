class KeyEvent {
    keyCode;
    ctrlKey;
    shiftKey;
    altKey;
    metaKey;

    constructor(keyCode, ctrlKey, shiftKey, altKey, metaKey) {
        this.keyCode = keyCode;
        this.ctrlKey = ctrlKey;
        this.shiftKey = shiftKey;
        this.altKey = altKey;
        this.metaKey = metaKey;
    }

    static fromEvent(e) {
        return new KeyEvent(e.code, e.ctrlKey, e.shiftKey, e.altKey, e.metaKey)
    }
}

export default KeyEvent;
