import React from 'react';
import {useSelector} from "react-redux";
import "./editor.css"
import {useSocketEmit} from "./Connection";

const REGEXP = /[A-Z\[\]@^_\\]/;
const LETTER_WIDTH = 43;

function calcText(text, cursorPosition) {
    let result = text || "";
    const length = result.length;
    if (length <= cursorPosition) {
        let count = cursorPosition - length + 1;
        return result + " ".repeat(count)
    }
    return result;
}

function getSymbols(userSession) {
    const symbols = [];
    const cursorPosition = userSession.cursor;
    const sessionText = calcText(userSession.text, cursorPosition);
    if (sessionText !== undefined && sessionText !== "") {
        let idx = 0;
        for (const letter of sessionText) {
            let symbolClassName = "letter ";
            let wrapperClassNAme = "wrapper ";
            const isImportant = letter.match(REGEXP);
            if (isImportant) {
                symbolClassName += "important ";
            }
            if (idx === cursorPosition) {
                wrapperClassNAme += "cursor "
            }
            symbols.push(
                <div key={idx} className={wrapperClassNAme}>
                    <svg width="35" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        {isImportant &&
                        <line x1="0" x2="31" y1="50" y2="50" strokeWidth="5" className={symbolClassName}/>}
                        <text x="0" y="40" className={symbolClassName}>{letter}</text>
                    </svg>
                </div>
            );
            idx++;
        }

    }
    return symbols;
}


export default function Editor() {
    const userSession = useSelector(state => state.userSession);
    const emit = useSocketEmit();

    return (
        <div className="container" ref={el => {
            if (el && el.offsetWidth) {
                emit("ROW_SIZE", Math.floor(el.offsetWidth / LETTER_WIDTH))
            }
        }}>
            <div className="editor">
                {getSymbols(userSession)}
            </div>
        </div>
    );
}

