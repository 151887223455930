import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import UserSessionReducer from "./UserSessionReducer";
import AssignmentReducer from "./AssignmentReducer";
import ErrorReducer from "./ErrorReducer";
import OkReducer from "./OkReducer";


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    userSession: UserSessionReducer,
    assignment: AssignmentReducer,
    error: ErrorReducer,
    ok: OkReducer,
});

export default createRootReducer

