import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from '@material-ui/core/styles';

import configureStore, {history} from "./redux/configureStore";
import theme from './theme';
import App from './App';

const rootElement = document.getElementById('root');
const preloadedState = {};
ReactDOM.render(
    <Provider store={configureStore(preloadedState)}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Switch>
                    <Route exact path="/assignment/:assignmentGUID" component={App}/>
                    <Route render={() => <div>"NOT MATCH"</div>}/>
                </Switch>
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    rootElement
);
