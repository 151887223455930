import {USER_SESSION} from "../../actions";
import UserSession from "../../model/UserSession";

export default function (state = {}, action) {
    switch (action.type) {
        case USER_SESSION:
            return UserSession.fromResponse(action.payload);
        default:
            return state;
    }
}
