import React from "react";
import {useSelector} from "react-redux";
import ReactMarkdown from 'react-markdown';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& img': {
            maxWidth: 800
        }
    },
}));

export default function AssignmentView(props) {
    const classes = useStyles();

    const assignment = useSelector(state => state.assignment);

    return (
        <Container>
            <div className={classes.root}>
                <Typography variant={"h6"}>{assignment.name}</Typography>
                <ReactMarkdown source={assignment.description}/>
            </div>
        </Container>
    );
}