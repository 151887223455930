import {UPDATE_ASSIGNMENT} from "../../actions";
import Assignment from "../../model/Assignment";

export default function (state = {}, action) {
    switch (action.type) {
        case UPDATE_ASSIGNMENT:
            return Assignment.fromResponse(action.payload);
        default:
            return state;
    }
}
