import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import {useSocketState} from "./Connection";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Toolbar from "@material-ui/core/Toolbar";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    green: {
        color: green[400]
    },
    red: {
        color: red[400]
    }
}));

export default function StatusBar() {
    const classes = useStyles();

    const userSession = useSelector(state => state.userSession);

    const socketState = useSocketState();
    return (
        <div className={classes.root}>
            <Toolbar>
                <SignalCellularAltIcon className={socketState ? classes.green : classes.red}/>
                <Typography variant="h6">{userSession.keyboard}</Typography>
            </Toolbar>
        </div>
    );
}