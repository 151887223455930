import {OK} from "../../actions";

export default function (state = "", action) {
    switch (action.type) {
        case OK:
            return action.payload;
        default:
            return state;
    }
}
