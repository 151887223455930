class UserSession {
    text;
    cursor;
    keyboard;

    constructor(text, cursor, keyboard) {
        this.text = text;
        this.cursor = cursor;
        this.keyboard = keyboard;
    }


    static fromResponse(response) {
        const {text, cursor, keyboard} = response;
        return new UserSession(text, cursor, keyboard);
    }
}

export default UserSession